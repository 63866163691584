import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Show';
import { MDBDataTable } from 'mdbreact';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Datatable from 'react-bs-datatable';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
const image = `${process.env.PUBLIC_URL}/img/map-location.png`;

const { SearchBar } = Search;
const tableColumns = [
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerStyle: { width: "4%" }
  },
  {
    dataField: "trackee_id",
    text: "Trackee",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "tracker_id",
    text: "Tracker",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "start_time",
    text: "Start Time",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "end_time",
    text: "End Time",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "share_time",
    text: "Share Time",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    headerStyle: { width: "10%" }
  },
  // {
  //   dataField: "action",
  //   text: "Action",
  //   headerStyle: { width: "10%" },
  //   csvExport: false
  // },
  {
    dataField: "view_map",
    text: "View Map",
    headerStyle: { width:"10%" },
    csvExport:false
  },
];


class Tracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      formData: [],
    }
    this.flag = 0;
    this.header = [
      { title: 'Sr. No.', prop: 'idd', filterable: true },
      { title: 'Employee Name   ', prop: 'first_name', filterable: true },
      { title: 'Employee SAP Code', prop: 'sap_code' },
      { title: 'Employee Mobile No.', prop: 'mobile_no' },
      { title: 'Employee Email', prop: 'email' },
      { title: 'Latitude      ', prop: 'user_lat' },
      { title: 'Longitude     ', prop: 'user_long' },
      { title: 'Date          ', prop: 'date' },
      { title: 'Time          ', prop: 'time' },
      { title: 'Panic Evidences', prop: 'action', cell: (row) => <div><center><i id={row.id} className="fa fa-eye ptr_css" onClick={() => this.view(row.id)}></i></center></div> },
    ];

    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };

  }

  view(e) {
    this.props.history.push('/maps/google_map/' + e);
  }

  edit(e) {
    this.props.history.push('/forms/add/' + e);
  }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

  componentDidMount() {
    fetch(myConstClass.BASE_URL + '/user/getFollowMe')
      .then(response => response.json())
      .then(response => {
        let i = 1;
        response.forEach(element => {
          var id = element.id;
          element.action = <div><i id={id} className="fa fa-map-marker ptr_css" onClick={() => this.view(id)}></i></div>;
          const file_link = `${myConstClass.BASE_URL+'/user/getTrackingById/' +id}`;
          element.view_map = <div><center><a target="_blank" href={file_link} title="View Map"><img id={id} src={image} style={{width: '20px',height: '16px'}} /></a></center></div>;
          element.id = i;
          i++;
        });
        this.setState({ formData: response })
      })
      .catch(err => console.log(err))

    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }

  }

  render() {

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">All Follow Me
            </h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />

        </Row>
        {/* <Row>
    <Col md={12} lg={12}>
        <Link to="/forms/send_notification" style={{float:'right'}}> <Button
                    color="primary"
                    variant="contained"
                    type="button"
                   
                >Send Notification</Button>
                </Link>
        </Col>
    </Row> */}
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <ToolkitProvider
                keyField="name"
                data={this.state.formData}
                columns={tableColumns}
                exportCSV
                search
              >
                {props => (
                  <div>

                    <CardBody>
                      <SearchBar {...props.searchProps} />
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4
                        bordered={false}
                        pagination={paginationFactory({
                          // sizePerPage: 25,
                          sizePerPage: 10,
                          sizePerPageList: [5, 10, 25, 50]
                        })}
                      />
                    </CardBody>
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Tracking);
