import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button, FormGroup, Label, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { CSVLink, CSVDownload } from "react-csv";
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AnimatedLineFormWithLabels from './components/AnimatedLineFormWithLabels';
import showResults from '../Show';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Datatable from 'react-bs-datatable';
import { MDBDataTable } from 'mdbreact';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import * as myConstClass from '../../../constant.js';
import '../../../common_db.css';
import 'font-awesome/css/font-awesome.min.css';
import Modal from '../../../shared/components/License';

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
const { SearchBar } = Search;

const tableColumns = [
  {
    dataField: "uid",
    text: "#",
    sort: true,
    headerStyle: { width: "5%" }
  },
  {
    dataField: "first_name",
    text: "Name",
    sort: true,
    headerStyle: { width: "15%" }
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "mobile_no",
    text: "Mobile No",
    sort: true,
    headerStyle: { width: "10%" }
  },
  // {
  //   dataField: "designation",
  //   text: "Designation",
  //   headerStyle: { width:"10%" },
  //   csvExport:false
  // },  {
  //   dataField: "branch_name",
  //   text: "Branch Name",
  //   headerStyle: { width:"10%" },
  //   csvExport:false
  // },
  // {
  //   dataField: "department",
  //   text: "Department",
  //   headerStyle: { width:"10%" },
  //   csvExport:false
  // },
  {
    dataField: "gender",
    text: "Gender",
    headerStyle: { width: "10%" },
    csvExport: false
  },
  {
    dataField: "date_of_birth",
    text: "DOB",
    headerStyle: { width: "10%" },
    csvExport: false
  },
  {
    dataField: "status",
    text: "Status",
    headerStyle: { width: "10%" },
    csvExport: false
  },
  {
    dataField: "action",
    text: "Actions",
    headerStyle: { width: "10%" },
    csvExport: false
  },
];

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      plans: [],
      formData: [],
      formDataCSV: '',
      AdminData: [],
      admin_select: '',
      session_tenant_id: '',
      status_id_value: 0,
    };
    this.change = this.change.bind(this);
    this.lchange = this.lchange.bind(this);
    this.flag = 0;
    this.plan_id = 0;
    this.status_id = 0;
    this.header = [
      { title: 'Sr. No.', prop: 'id', filterable: true },
      { title: 'Employee Name', prop: 'first_name', filterable: true },
      { title: 'Email', prop: 'email' },
      { title: 'Mobile No.', prop: 'mobile_no' },
      { title: 'Vertical', prop: 'vertical' },
      { title: 'Gender', prop: 'gender' },
      { title: 'Date of Birth', prop: 'date_of_birth' },
      { title: 'Age', prop: 'age' },
      { title: 'Status', prop: 'status' },
      { title: 'Action', prop: 'action', cell: (row) => <div><i id={row.id} className="fa fa-remove ptr_css" onClick={() => this.remove(row.id)}></i>&nbsp;&nbsp;<i id={row.id} className="fa fa-edit ptr_css" onClick={() => this.edit(row.id)}></i>&nbsp;&nbsp;<i id={row.id} className="fa fa-eye ptr_css"></i></div> },
    ];

    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
  }

  remove(e) {
    var tid = 1;
    if (localStorage.getItem('uid')) {
      tid = localStorage.getItem('uid');
    }

    this.flag = 1;
    fetch(myConstClass.BASE_URL + `/user/deleteUser`, {
      method: 'POST',
      body: e,
    }).then(toast('User Deleted Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS }),

      fetch(myConstClass.BASE_URL + '/user/getUsers/' + this.status_id + '/' + this.plan_id + '/'+tid)
        .then(response => response.json())
        .then(response => {
          let i = 1;
          response.forEach(element => {
            let id = element.uid;
            element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() => this.remove(id)}></i><i id={id} onClick={() => this.edit(id)} className="fa fa-edit ptr_css"></i><Modal
              color="primary"
              title='Plan Details'
              btn="Info"
              header
              message={element.plan}
            /></div>;
            // console.log(element);
            element.uid = i;
            i++;
            delete element.plan;
            //element.splice(8,1);
          });
          // this.setState({posts: response})
          this.setState({ formData: response })
        })
        .catch(err => console.log(err))

    )
      .catch();


  }

  lchange(event) {//alert(event.target.value);alert(this.plan_id);
    this.plan_id = event.target.value;
    fetch(myConstClass.BASE_URL + '/user/getUsers/' + this.status_id + '/' + this.plan_id)
      .then(response => response.json())
      .then(response => {
        let i = 1;
        response.forEach(element => {
          let id = element.uid;
          element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() => this.remove(id)}></i><i id={id} onClick={() => this.edit(id)} className="fa fa-edit ptr_css"></i><Modal
            color="primary"
            title='Plan Details'
            btn="Info"
            header
            message={element.plan}
          /></div>;
          // console.log(element);
          element.uid = i;
          i++;
          delete element.plan;
          //element.splice(8,1);
        });
        // this.setState({posts: response})
        this.setState({ formData: response })
      })
      .catch(err => console.log(err))
  }

  change(event) {
    this.setState({ status_id_value: event.target.value });
    var tid = 1;
    if (localStorage.getItem('uid')) {
      tid = localStorage.getItem('uid');
    }
    this.status_id = event.target.value;
    var admin_select = this.state.admin_select;
    fetch(myConstClass.BASE_URL + '/user/getUsers/' + this.status_id + '/' + this.plan_id + "/" + tid + "/" + admin_select)
      .then(response => response.json())
      .then(response => {
        let i = 1;
        response.forEach(element => {
          let id = element.uid;
          element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() => this.remove(id)}></i><i id={id} onClick={() => this.edit(id)} className="fa fa-edit ptr_css"></i><Modal
            color="primary"
            title='Plan Details'
            btn="Info"
            header
            message={element.plan}
          /></div>;
          element.uid = i;
          i++;
          delete element.plan;
          //element.splice(8,1);
        });
        // this.setState({posts: response})
        this.setState({ formData: response })
      })
      .catch(err => console.log(err))
  }

  edit(e) {
    //alert(e);
    this.props.history.push('/forms/add/' + e);
  }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

  componentDidMount() {
    /*
    fetch(myConstClass.BASE_URL+'/user/getUsers')
    .then(response => response.json())
    .then(response =>{
      let i=1;
      response.forEach(element => {
        let id=element.uid;
      element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() =>this.remove(id)}></i><i id={id} onClick={() =>this.edit(id)} className="fa fa-edit ptr_css"></i><Modal
      color="primary"
      title='Plan Details'
      btn="Info"
      header
      message={element.plan}
    /></div>;
       // console.log(element);
       element.uid=i;
       i++;
       delete element.plan;
       //element.splice(8,1);
      });
      // this.setState({posts: response})
      this.setState({formData: response})
    })
    .catch(err => console.log(err))

    */

    // session uid
    if (localStorage.getItem('uid')) {
      if (localStorage.getItem('uid') != 1) {
        this.setState({ session_tenant_id: localStorage.getItem('uid') })
      }
    }
    fetch(myConstClass.BASE_URL + '/user/getAdmin')
      .then(response => response.json())
      .then(response => {
        let i = 1;
        response.forEach(element => {
          i++;
        });
        this.setState({ AdminData: response })
      })
      .catch(err => console.log(err))

    var tid = 1;
    if (localStorage.getItem('uid')) {
      tid = localStorage.getItem('uid');
    }
    fetch(myConstClass.BASE_URL + '/user/getUsersEncode/' + tid)
      .then(response => response.json())
      .then(response => {
        const responseNewArray = response.map(item => ({
          uid: atob(item.uid),
          first_name: atob(item.first_name),
          email: atob(item.email),
          mobile_no: atob(item.mobile_no),
          date_of_birth: atob(item.date_of_birth),
          status: atob(item.status),
          gender: atob(item.gender),
        }));
        let i = 1;
        responseNewArray.forEach(element => {
          let id = element.uid;
          element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() => this.remove(id)}></i><i id={id} onClick={() => this.edit(id)} className="fa fa-edit ptr_css"></i>
            {/* <Modal
          color="primary"
          title='Plan Details'
          btn="Info"
          header
          message={element.plan}
        /> */}
          </div>;
          element.uid = i;
          i++;
          delete element.plan;
        });
        // this.setState({posts: response})
        this.setState({ formData: responseNewArray })
      })
      .catch(err => console.log(err))

    fetch(myConstClass.BASE_URL + '/user/getPlans')
      .then(response => response.json())
      .then(response => {

        this.setState({ plans: response })
      })
      .catch(err => console.log(err))

    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }

    fetch(myConstClass.BASE_URL + `/user/getUsersCSV`)
      .then(response => response.json())
      .then(response => this.setState({ formDataCSV: response }))
  }
  handleAdminChange = (event) => {
    this.setState({ admin_select: event.target.value });

    var tid = 1;
    if (localStorage.getItem('uid')) {
      tid = localStorage.getItem('uid');
    }
    this.status_id = this.state.status_id_value;
    var admin_select = event.target.value;
    fetch(myConstClass.BASE_URL + '/user/getUsers/' + this.status_id + '/' + this.plan_id + "/" + tid + "/" + admin_select)
      .then(response => response.json())
      .then(response => {
        let i = 1;
        response.forEach(element => {
          let id = element.uid;
          element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() => this.remove(id)}></i><i id={id} onClick={() => this.edit(id)} className="fa fa-edit ptr_css"></i><Modal
            color="primary"
            title='Plan Details'
            btn="Info"
            header
            message={element.plan}
          /></div>;
          // console.log(element);
          element.uid = i;
          i++;
          delete element.plan;
          //element.splice(8,1);
        });
        // this.setState({posts: response})
        this.setState({ formData: response })
      })
      .catch(err => console.log(err))
  }
  render() {

    const headers = [
      { label: "#", key: "uid" },
      { label: "Name", key: "name" },
      { label: "Email", key: "email" },
      { label: "Mobile No.", key: "mobile_no" },
      { label: "Gender", key: "gender" },
      { label: "DOB", key: "dob" },
      { label: "Status", key: "status" },
    ];

    //console.log('this.state.posts',this.state.posts);

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">All Users
            </h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />

        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <ToolkitProvider
                keyField="name"
                data={this.state.formData}
                columns={tableColumns}
                exportCSV
                search
              >
                {props => (
                  <div>
                    <CardBody>
                      <Row>
                        <Col md={2} lg={2}>
                          {/* <Input type="select" name="select" id="plan_select" onChange={this.lchange}>
            <option value="0">Select Plan</option>
           {this.state.plans.map((number) =>
  <option value={number.id}>{number.title}</option>
)
           }
          </Input> */}
                        </Col>

                        <Col md={4} lg={4}>
                          <FormGroup>
                            {/* <Label for="exampleSelect">Status</Label> */}
                            <Input type="select" name="select" id="status_select" onChange={this.change}>
                              <option value="0">Select Status</option>
                              <option value="1">Active</option>
                              <option value="2">In-active</option>
                              <option value="3">Suspended</option>
                            </Input>
                          </FormGroup>
                        </Col>

                        <Col md={4} lg={4}>
                          {this.state.session_tenant_id == "" ?
                            <FormGroup>
                              {/* <Label for="exampleSelect">Status</Label> */}
                              <Input type="select" name="admin_select" id="admin_select" onChange={this.handleAdminChange}>
                                <option value="0">Select Tenant</option>
                                {this.state.AdminData.map((number) =>
                                  <option value={number.uid}>{number.first_name}</option>
                                )}
                              </Input>
                            </FormGroup>
                            :
                            ""}
                        </Col>

                        <Col md={2} lg={2}>
                          {/* {this.state.formDataCSV ?
                            <CSVLink filename={"Users.csv"}
                              className="btn btn-secondary" style={{ 'float': 'right', 'margin': '0px 17px' }} data={this.state.formDataCSV} headers={headers}>Export</CSVLink>
                            : ''} */}
                          {this.state.formData ?
                            <CSVLink filename={"Users.csv"}
                              className="btn btn-secondary" style={{ 'float': 'right', 'margin': '0px 17px' }} data={this.state.formData} headers={headers}>Export</CSVLink>
                            : ''}
                          {/* <Link to="/forms/add" style={{float:'right'}}> <Button
                    color="primary"
                    variant="contained"
                    type="button"
                   
                >Add Employee</Button>
                </Link> */}
                        </Col>
                      </Row>
                      <hr style={{ margin_bottom: '2px !important', margin_top: '0px !important' }} />
                      {/* <Datatable
  tableHeader={this.header}
  tableBody={this.state.posts}
  keyName="userTable"
  tableClass="striped hover responsive"
  rowsPerPage={10}
  rowsPerPageOption={[5, 10, 15, 20]}
  initialSort={{prop: "`title`", isAscending: true}}
  onSort={this.onSortFunction}
  labels={this.customLabels}
  table
/> */}
                      {/* <MDBDataTable
      striped
      hover
      data={this.state.posts}
    /> */}
                      {/* <MDBTable pagination={true}>
      <MDBTableHead columns={this.columns} />
      <MDBTableBody rows={this.state.posts} />
    </MDBTable> */}

                      <SearchBar {...props.searchProps} />
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4
                        bordered={false}
                        pagination={paginationFactory({
                          // sizePerPage: 25,
                          sizePerPage: 10,
                          sizePerPageList: [5, 10, 25, 50]
                        })}
                      />

                    </CardBody>
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Users);
