import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { changeToLight, changeToDark } = this.props;

    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          {/* <SidebarLink title="Dashboard Default" icon="home" route="/dashboard_default" onClick={this.hideSidebar} /> */}
          <SidebarCategory title="Manage Users" icon="users">
            {/* <SidebarLink title="Add Employee" route="/forms/add" onClick={this.hideSidebar} />
            <SidebarLink title="Add Bulk Employee" route="/forms/addbulk" onClick={this.hideSidebar} /> */}
            <SidebarLink title="User List" route="/forms/users" onClick={this.hideSidebar} />
            <SidebarLink title="Bulk upload" route="/forms/user_bulk_upload" onClick={this.hideSidebar} />
          </SidebarCategory>
          {/* {localStorage.getItem('utype') == '1' ? <SidebarCategory title="Manage Admin" icon="user">        
            <SidebarLink title="Add Admin" route="/forms/add_admin" onClick={this.hideSidebar} />
            <SidebarLink title="List Admin" route="/forms/admin_list" onClick={this.hideSidebar} />
          </SidebarCategory>:''} */}
          {localStorage.getItem('utype') == '1' ? <SidebarCategory title="Manage Tenant" icon="user">        
            <SidebarLink title="Add Tenant" route="/forms/add_admin" onClick={this.hideSidebar} />
            <SidebarLink title="List Tenant" route="/forms/admin_list" onClick={this.hideSidebar} />
          </SidebarCategory>:''}
           
          <SidebarLink icon="eye" title="Panic Evidence" route="/forms/evidence_list" onClick={this.hideSidebar} />
          <SidebarLink icon="eye" title="Posh Requests" route="/forms/posh_evidence_list" onClick={this.hideSidebar} />
          {/* <SidebarLink icon="user" title="Citizen Cop" route="/forms/cop_list" onClick={this.hideSidebar} /> */}
          <SidebarCategory title="Manage Locations" icon="map-marker">
            {/* <SidebarLink title="Add Bulk Location" route="/forms/addLocation" onClick={this.hideSidebar} />
            <SidebarLink title="List Location" route="/forms/locations" onClick={this.hideSidebar} />
            <SidebarLink title="Group Location" route="/forms/group_locations" onClick={this.hideSidebar} /> */}
            <SidebarLink title="Tracking" route="/forms/tracking" onClick={this.hideSidebar} />
            {/* <SidebarLink title="Follow Me" route="/forms/follow_me" onClick={this.hideSidebar} /> */}
            {/* <SidebarLink title="All Tracking Devices" route="/forms/device_list" onClick={this.hideSidebar} /> */}

          </SidebarCategory>
          {/* {localStorage.getItem('utype') == '1' ? 
          <div> */}
          {/* <SidebarLink icon="alarm" title="Notifications" route="/forms/notifications" onClick={this.hideSidebar} /> */}
          <SidebarLink icon="alarm" title="Follow me" route="/forms/pre_trigger_notifications" onClick={this.hideSidebar} />
          <SidebarCategory title="Notifications" icon="alarm">
            <SidebarLink title="Regular Notifications" route="/forms/notifications" onClick={this.hideSidebar} />
            <SidebarLink title="Morning Greetings" route="/forms/morning_greetings" onClick={this.hideSidebar} />
            <SidebarLink title="Birthday Greetings" route="/forms/birthday_greetings" onClick={this.hideSidebar} />
            <SidebarLink title="Safety Tips" route="/forms/safety_tips_msg" onClick={this.hideSidebar} />
            <SidebarLink title="Bulletin News" route="/forms/bulletin_news" onClick={this.hideSidebar} />
          </SidebarCategory>

          {/* <SidebarLink icon="alarm" title="Pre Trigger Notifications" route="/forms/pre_trigger_notifications" onClick={this.hideSidebar} /> */}
          {/* <SidebarLink icon="envelope" title="View Queries" route="/forms/queries" onClick={this.hideSidebar} /> */}
          {/* <SidebarCategory title="CMS Pages" icon="file-empty"> */}
            {/* <SidebarLink title="About Us" route="/forms/about_us" onClick={this.hideSidebar} />
            <SidebarLink title="Privacy Policy" route="/forms/privacy_policy" onClick={this.hideSidebar} />
            <SidebarLink title="Terms and Conditions" route="/forms/terms" onClick={this.hideSidebar} /> */}
            {/* <SidebarLink title="Help" route="/forms/help" onClick={this.hideSidebar} />
            <SidebarLink title="Tutorial" route="/forms/tutorial" onClick={this.hideSidebar} /> */}
          {/* </SidebarCategory> */}


          {/* </div> :''} */}
        </ul>
       
      </div>
    );
  }
}

export default SidebarContent;
