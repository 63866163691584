

import React, { Component } from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Col, Container, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Show';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Datatable from 'react-bs-datatable';
import { MDBDataTable } from 'mdbreact';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const { SearchBar } = Search;
const tableColumns = [
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerStyle: { width: "10%" }
  }, {
    dataField: "title",
    text: "Title",
    sort: true,
    headerStyle: { width: "20%" }
  },
  {
    dataField: "description",
    text: "Description",
    sort: true,
    headerStyle: { width: "20%" }
  },
  {
    dataField: "created_at",
    text: "Date/Time",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "action",
    text: "Action",
    headerStyle: { width: "10%" },
    csvExport: false
  },
];

const image = `${process.env.PUBLIC_URL}/img/map-location.png`;

class Bulletin_news extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      formData: [],
      formDataDownload: {
        sapcode: '',
      },
      reportsapid: '',
      selectedFile: '',
    }
    this.flag = 0;
    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  view(e) {
    this.props.history.push('/forms/bulletin_news_view/' + e);
  }

  handleChange(event) {
    this.setState({ reportsapid: event.target.value });
  }

  add_bulletin_news() {
    this.props.history.push('/forms/add_bulletin_news');
  }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

  edit(e) {
    this.props.history.push('/forms/edit_bulletin_news/' + e);
  }

  remove(e) {
    this.flag = 1;
    fetch(myConstClass.BASE_URL + `/bulletin_news/deleteBulletinNews`, {
      method: 'POST',
      body: e,
      //}).then(toast('Message Deleted Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS }),
    }).then(response => response.json()).then((response) => {
      if (response == true) {
        toast('Bulletin News Deleted Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })
        fetch(myConstClass.BASE_URL + '/bulletin_news/getBulletinNews')
          .then(response => response.json())
          .then(response => {
            let i = 1;
            response.forEach(element => {
              let id = element.id;
              element.action = <div><center><i id={id} className="fa fa-eye ptr_css" onClick={() => this.view(id)}></i>&nbsp;&nbsp;<i className="fa fa-remove ptr_css" onClick={() => this.remove(id)}></i>&nbsp;&nbsp;<i id={id} className="fa fa-edit ptr_css" onClick={() => this.edit(id)}></i></center></div>;
              element.id = i;
              i++;
            });
            this.setState({ formData: response })
          }).catch(err => console.log(err))
      } else {
        toast('Failed to delete.', { containerId: 'B', type: toast.TYPE.ERROR })
      }
    }).catch();
  }

  componentDidMount() {
    var tid = 1;
    if (localStorage.getItem('uid')) {
      tid = localStorage.getItem('uid');
    }
    // fetch(myConstClass.BASE_URL + '/bulletin_news/getBulletinNews')
    fetch(myConstClass.BASE_URL + '/bulletin_news/getBulletinNews/'+tid)
      .then(response => response.json())
      .then(response => {
        let i = 1;
        response.forEach(element => {
          var id = element.id;
          element.action = <div><center><i id={id} className="fa fa-eye ptr_css" onClick={() => this.view(id)}></i>&nbsp;&nbsp;<i className="fa fa-remove ptr_css" onClick={() => this.remove(id)}></i>&nbsp;&nbsp;<i id={id} className="fa fa-edit ptr_css" onClick={() => this.edit(id)}></i></center></div>;
          element.id = i;
          i++;
        });
        this.setState({ formData: response })
      }).catch(err => console.log(err))
    if (!localStorage.getItem('session_id')) {
      this.props.history.push('/log_in');
    }

  }

  render() {

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">All Bulletin News</h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <ToolkitProvider
                keyField="name"
                data={this.state.formData}
                columns={tableColumns}
                exportCSV
                search
              >
                {props => (
                  <div>
                    <CardBody>
                      <Row>                 </Row>
                      <Button color="primary" style={{ float: 'right' }} onClick={() => this.add_bulletin_news()} > Add Bulletin News</Button>
                      <SearchBar {...props.searchProps} />
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4
                        bordered={false}
                        pagination={paginationFactory({
                          sizePerPage: 10,
                          sizePerPageList: [5, 10, 25, 50]
                        })}
                      />
                    </CardBody>
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Bulletin_news);
